@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  background-image: url("./img/Background_Blue_1500.png");
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.swal2-popup {
  background-color: #1c1b1b;
  color: #fff;
  border-radius: 10px;
}

.swal2-title {
  color: #ffffff;
}

.swal2-content {
  color: #dddddd;
}

.password-input-container input {
  width: 100%;
  padding-right: 40px;
}

.eye-icon:hover {
  color: #0056b3;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: #007bff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.input-icon-container.fraca {
  border: 2px solid rgb(255, 0, 0);
}

.input-icon-container.media {
  border: 2px solid rgb(255, 166, 0);
}

.input-icon-container.forte {
  border: 2px solid rgba(0, 210, 119, 0.78);
}

img#icon-img {
  width: 7rem;
}

img {
  width: 15rem;
}

label#check {
  cursor: pointer;
}

label#check:hover {
  color: #d6eaff;
}

.btn-redpass span {
  color: #0088ff;
  cursor: pointer;
}

.btn-redpass span:hover {
  color: #9ccbfd;
  cursor: pointer;
}

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.auth-container h1 {
  margin-bottom: 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUpTitle 0.5s ease-in-out forwards;
}

@keyframes fadeInUpTitle {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .btn-redpass span {
    font-size: 15px;
  }
}

.auth-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.auth-container input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid none;
  border-radius: 5px;
  font-size: 16px;
}

.input-icon-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border: 1px solid #b3cde8;
  border-radius: 5px;
  background-color: #f4f7fb;
  position: relative;
}

.icon-background {
  background-color: transparent;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-icon {
  color: #007bff;
  font-size: 1.5rem;
}

.input-icon-container input {
  width: 100%;
  padding: 4px;
  padding-right: 40px;
  border: none;
  outline: none;
  font-size: 16px;
  background-color: transparent;
}

.login-methods-toggle {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.login-methods-toggle .btn {
  flex: 1;
}

/* Estilos opcionais para melhorar a aparência */
.login-methods-toggle .btn-secondary,
.login-methods-toggle .btn-outline-secondary {
  width: 150px;
}

.eye-icon {
  position: absolute;
  right: 10px;
  color: #007bff;
  cursor: pointer;
  font-size: 1.2rem;
}

.auth-container button:disabled {
  background-color: #ccc;
}

.auth-container button i {
  margin-right: 5px;
}

.auth-container p {
  margin-top: 10px;
  text-align: center;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}

body::-webkit-scrollbar {
  width: 9px;
}

body::-webkit-scrollbar-track {
  background: #050407;
}

body::-webkit-scrollbar-thumb {
  background-color: #050407;
  border-radius: 5px;
  border: 1px solid #1578e8;
  transition: background-color 0.3s ease;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #102845;
}

.swal2-confirm {
  background-color: transparent;
  border: 2px solid #1578e8;
  color: #1578e8;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.swal2-cancel {
  background-color: transparent;
  border: 2px solid #de053b;
  color: #de053b;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.swal2-cancel:hover {
  background-color: #de053b;
  color: #000;
}

.swal2-confirm:hover {
  background-color: #1578e8;
  color: #000;
}

.btn-primary {
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-primary:hover {
  transform: scale(1.05);
}

.password-strength-meter .progress {
  height: 5px;
}

.password-strength-meter small {
  display: block;
  margin-top: 5px;
}
