.privacy-policy-container {
  color: #e0e0e0;
  padding: 20px;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
}
  
.responsive-img {
  width: 100%;
}

/* Conteúdo Principal */
.policy-content {
  background-color: #1e1e1e; /* Fundo um pouco mais claro */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* Títulos das Seções */
.section-title {
  font-size: 1.5rem;
}

/* Descrições */
.policy-description {
  font-size: 1rem;
  line-height: 1.75;
}

/* Listas */
.policy-description ul {
  list-style-type: disc;
  padding-left: 20px;
}

.policy-description ul li {
  margin-bottom: 0.5rem;
}

/* Ícones nas Seções */
.feature-icon {
  font-size: 2rem;
  color: #17a2b8; /* Cor dos ícones */
}

/* Links */
a.text-info {
  color: #17a2b8;
  text-decoration: underline;
}

a.text-info:hover {
  color: #138496;
  text-decoration: none;
}

/* Responsividade */
@media (max-width: 576px) {
  .policy-content {
    padding: 1rem;
  }

  .section-title {
    font-size: 1.2rem;
  }

  .policy-description {
    font-size: 0.9rem;
  }

  .feature-icon {
    font-size: 1.5rem;
  }
}

