/* src/components/DoorPage.css */

.body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #0d0d0d;
    margin: 0;
    perspective: 1000px;
    overflow: hidden;
    transition: transform 1s ease-in-out;
    position: relative;
}

.door-frame {
    width: 130px;
    height: 260px;
    background-color: #3b2b2b;
    position: absolute;
    border-radius: 2px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}

.door {
    width: 120px;
    height: 250px;
    background: linear-gradient(135deg, #5e3a1e, #704214, #5e3a1e);
    border: 3px solid #3b2b2b;
    border-radius: 3px;
    position: relative;
    transform-origin: left center;
    cursor: pointer;
    transition: transform 1.2s ease-in-out, box-shadow 1.2s ease-in-out;
    overflow: hidden;
}

.door::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(45deg,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2) 5px,
            transparent 5px,
            transparent 10px);
    opacity: 0.5;
}

.door::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle at 30% 30%, rgba(0, 0, 0, 0.2), transparent 15%),
        radial-gradient(circle at 70% 70%, rgba(0, 0, 0, 0.1), transparent 20%),
        radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.3), transparent 25%);
    opacity: 0.7;
    pointer-events: none;
}

.handle {
    position: absolute;
    right: 20px;
    top: 120px;
    width: 10px;
    height: 10px;
    background-color: #a89e66;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.door.open {
    transform: rotateY(-110deg);
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.8);
}

.corridor {
    position: absolute;
    width: 80px;
    height: 240px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) perspective(500px) rotateY(0deg);
    background: radial-gradient(circle at top, #444, #1a1a1a 60%, #000);
    border-radius: 2px;
    box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 1.2s ease-in-out;
}

.open-light .corridor {
    opacity: 1;
}

.dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 2s ease-in-out;
    pointer-events: none;
}

.open-light .dark-overlay {
    background-color: rgba(0, 0, 0, 0.8);
}

.lamp {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    background-color: #ffd700;
    border-radius: 50%;
    box-shadow: 0 0 15px 10px rgba(255, 215, 0, 0.7);
}

.light-beam {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 300px;
    background: radial-gradient(ellipse at top, rgba(255, 215, 0, 0.5), rgba(0, 0, 0, 0) 70%);
    border-radius: 50% 50% 0 0 / 60% 60% 0 0;
    opacity: 0.7;
    pointer-events: none;
}

.open-light {
    transform: scale(1.5);
}
