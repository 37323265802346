.room-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #16202b;
  color: #fff;
  padding: 0;
  border-radius: 10px;
}

header {
  width: 100%;
  background-color: #1f2a38;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 1000;
}

.room-title {
  text-align: center;
  margin-bottom: 20px;
}

.auds {
  font-size: 14px;
}

.avatar-option.selected {
  border: 3px solid #007bff;
}

.visually-hidden {
  display: none;
}

.toggle-container {
  background-color: transparent;
  border-radius: 12px;
  padding: 15px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.toggle-header {
  font-size: 1rem;
  font-weight: 600;
  transition: color 0.3s ease;
}

.icon-clock {
  color: #ffffff;
  transition: color 0.3s ease;
}

.toggle-title {
  font-size: 1.05rem;
  font-weight: 600;
  transition: color 0.3s ease;
}

.toggle-header.active .toggle-title {
  color: #34c759;
}

.toggle-header.inactive .toggle-title {
  color: #ff3b30;
}

.toggle-container:active {
  transform: scale(0.98);
}

.message {
  animation: fadeIn 0.4s ease-in-out, pulse 1.5s ease-in-out infinite;
  transition: transform 0.3s, box-shadow 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px) rotate(-1deg) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) rotate(0deg) scale(1);
  }
}

@keyframes pulse {
  0%,
  100% {
    box-shadow: 0 0 0 0 rgba(0, 89, 172, 0.3);
  }
  50% {
    box-shadow: 0 0 10px 10px rgba(0, 89, 172, 0);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}

.message:hover {
  transform: scale(1.02) rotate(0.5deg);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.list-group {
  margin-top: 2rem;
}

.message-container {
  flex: 1;
  margin: 20px;
  padding: 10px;
  background-color: #1f2a38;
  border-radius: 8px;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}

.message-container div {
  transition: all 0.3s;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.message-container div:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.label-checks span:hover {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.message-container {
  overflow-y: scroll;
}

.message-container::-webkit-scrollbar {
  width: 2px;
}

.message-container::-webkit-scrollbar-track {
  background: #0061df;
  border-radius: 10px;
}

.message-container::-webkit-scrollbar-thumb {
  background: #013854;
  border-radius: 10px;
}

.message-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.message-container {
  scrollbar-width: none;
  scrollbar-color: #131e2b #0061df;
}

@media (max-width: 768px) {
  .message-container {
    max-height: 370px;
  }

  .auds {
    font-size: 10px;
  }
}

.custom-alert {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.message {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  border-radius: 15px;
  margin-bottom: 10px;
  max-width: 80%;
  position: relative;
  word-wrap: break-word;
  word-break: break-word;
  animation: fadeIn 0.4s ease-in-out, pulse 1.5s ease-in-out infinite;
  transition: transform 0.3s, box-shadow 0.3s;
}

.sub-textMsg small {
  font-size: 10.7px;
}

.typing-indicator {
  margin-bottom: 10px;
}

.status-message {
  color: green;
  text-align: center;
}

button {
  margin: 5px;
}

h3 {
  margin-top: 20px;
}

.input-audio-container {
  position: relative;
}

.input-audio-container input {
  flex: 1;
  border-radius: 5px;
}

.input-audio-container audio {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  margin: 0 10px;
  display: none;
}

.input-audio-container.audio-active audio {
  display: block;
}

.audio-controls-container-22 {
  background-color: #fff;
  border-radius: 10rem;
  width: 100%;
  justify-content: center;
}

.audio-controls-container-22 audio {
  flex: 1;
  max-width: 100%;
}

.dropdown-menu {
  background-color: #002c3f;
  border: 1px solid #005a8b;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dropdown-item {
  color: #fff;
  padding: 10px;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  border-radius: 12px;
  background-color: #00a6e8;
  color: #fff;
}

@keyframes slideIn {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.title-container {
  margin-top: 70px; /* Espaço para o cabeçalho fixo */
  text-align: center;
  padding: 10px 0;
  background-color: #1f2a38;
  animation: slideIn 1s ease-out;
  display: flex;
  align-items: center;
}

.title-container h1 {
  font-size: 1.7rem;
  color: #ffffff;
  font-weight: bold;
}

.icon-bordered {
  background-color: #ffffff;
  border: 3px solid #000000;
  color: #000000;
  border-radius: 50%;
  padding: 5px;
}

.text-bordered {
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 9px;
  color: #00c3ffac;
}

.play-button {
  background-color: #0059ac;
  border: none;
  border-radius: 5%;
  width: 240px;
  height: 45px;
  cursor: pointer;
  margin-right: 12px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.play-button:hover {
  background-color: #0059ac;
  transform: scale(1.1);
}

.play-button:focus {
  outline: none;
}

.play-button i {
  color: white;
  font-size: 10rem;
}

.div-title-expul {
  background-color: transparent;
}

.colorful-spinner {
  color: #3498db;
}

@media (max-width: 768px) {
  h3.title-bloq {
    text-align: center;
  }

  .div-title-expul {
    margin: auto;
    text-align: center;
  }

  .audio-controls-container-22 button {
    border-radius: 10rem;
  }
}

.btn-container {
  position: relative;
}

.btn-group {
  flex-wrap: nowrap;
}

.navbar-toggler .fa-ellipsis-v {
  font-size: 1rem;
  color: #fff;
}

@media (max-width: 768px) {
  .btn-group.d-none {
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 8px;
  }

  .container-fluid h1 {
    font-size: 0.9rem;
    height: 0.1rem;
  }

  .text-bordered {
    font-size: 0.9rem;
  }

  .alert-msg-rols {
    font-size: 0.7rem;
    font-weight: 400;
  }
}

.input-with-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.msg-user1,
.msg-user2 {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  padding: 10px 10px 10px 10px;
  padding-right: 10px;
  background-color: #2c3e50;
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.msg-user1:hover,
.msg-user2:hover {
  background-color: #1a252f;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.input-with-icon input::placeholder {
  color: #a9a9a9;
  opacity: 1;
}

.btn-icon {
  cursor: pointer;
  font-size: 1.2em;
  color: #007bff;
}

.btn-icon:hover {
  border: none;
  box-shadow: 0px 4px 10px rgb(255, 255, 255);
}

.transition-icon {
  transition: transform 0.3s ease;
}

.transition-icon.rotated {
  transform: rotate(90deg);
}

.reactions {
  display: flex;
  margin-top: 5px;
}

.reactions button {
  background-color: transparent;
  border: none;
  padding: 2px 5px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s, color 0.2s;
}

.reactions button.active {
  color: #17a2b8;
  background-color: rgba(23, 162, 184, 0.1);
  border-radius: 5px;
}

.reactions button:hover {
  background-color: rgba(23, 162, 184, 0.1);
  border-radius: 5px;
}

.reactions .btn-primary {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.reactions .btn-outline-primary {
  color: #17a2b8;
  border-color: #17a2b8;
}

.reactions .btn-primary:hover {
  background-color: #138496;
  border-color: #117a8b;
}

.reactions .btn-outline-primary:hover {
  background-color: #17a2b8;
  color: #fff;
}
