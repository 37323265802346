@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  background-color: #16202b;
  font-family: "Roboto", sans-serif;
  color: #fff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  opacity: 0;
  animation: fadeIn 0.5s forwards, slideIn 0.5s forwards;
}

button {
  transition: background-color 0.3s ease, transform 0.2s ease,
    box-shadow 0.3s ease;
}

.navbar-brand img {
  width: 60px;
  transition: transform 0.3s ease;
}

.navbar-brand img:hover {
  transform: rotate(10deg) scale(1.1);
}

.success-message {
  color: green;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.error-message {
  color: red;
  opacity: 0;
  animation: fadeIn 0.5s forwards, shake 0.5s forwards;
}

.text-animated {
  opacity: 0;
  animation: fadeInUp 0.5s forwards, whisper 2s infinite alternate;
}

.swal-popup-dark {
  border-radius: 15px;
  border: 2px solid #000000;
}

.benefits-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 20px;
}

.benefits-list li {
  transition: transform 0.3s ease, color 0.3s ease;
  opacity: 0;
  animation: fadeInUp 0.5s forwards;
}

.benefits-list li:hover {
  transform: translateY(-5px);
}

.benefits-list li:nth-child(1) {
  animation-delay: 0.2s;
}

.benefits-list li:nth-child(2) {
  animation-delay: 0.4s;
}

.benefits-list li:nth-child(3) {
  animation-delay: 0.6s;
}

.swal-confirm-button-dark {
  background-color: #4abee2;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.1em;
  transition: background-color 0.3s ease, transform 0.2s ease,
    box-shadow 0.3s ease;
}

.swal-confirm-button-dark:hover {
  background-color: #42a2bf;
  color: #151617;
  transform: scale(1.05);
  box-shadow: 0 4px 20px rgba(66, 162, 191, 0.5);
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  label {
    font-size: 13px;
  }

  span {
    font-size: 14px;
  }

  img.img-u53 {
    width: 16.8rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shake {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-2px);
  }
  50% {
    transform: translate(2px);
  }
  75% {
    transform: translate(-2px);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes whisper {
  0% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(2px);
  }
}

.button-pulse {
  animation: pulse 1s infinite;
}

.responsive-img {
  max-width: 440px;
  width: 100%;
}

@media (max-width: 768px) {
  .responsive-img {
    width: 227px;
  }

  li {
    font-size: 14px;
  }

  span {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .welcome-section h2 {
    font-size: 1rem; 
  }

  .welcome-section h1 {
    font-size: 1rem; 
  }

  .benefits-list li {
    font-size: 0.7rem;
  }
}
