.login-section button {
  width: 10rem;
  height: 3rem;
}

.logo-image {
  width: 50rem;
}

.clickable-img {
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.intro-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  animation: slideIn 1s ease-in-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.content-left {
  flex: 1;
  margin-right: 40px;
}

.content-left h2 {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: left;
}

.content-left p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  animation: fadeInText 1.5s ease-in-out;
}

@keyframes fadeInText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.visual-image {
  width: 1000rem;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.visual-image:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.8);
}

.cookie-consent {
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.95);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 10000;
  opacity: 1;
  animation: fadeInCookie 0.8s ease-in-out;
}

.cookie-consent p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #c0c0c0;
  opacity: 1;
}

@keyframes fadeInCookie {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.cookie-logo {
  width: 480px;
  margin-bottom: 20px;
}

.cookie-consent p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #c0c0c0;
}

.cookie-consent .btn {
  width: 45%;
  padding: 10px;
  font-weight: bold;
  color: white;
}

.cookie-consent .btn-primary.ac {
  background-color: #007bff;
  border: none;
}

.cookie-consent .btn-danger.rc {
  background-color: #dc3545;
  border: none;
}

.cookie-consent .btn-primary.ac:hover {
  background-color: #0056b3;
}

.cookie-consent .btn-danger.rc:hover {
  background-color: #c82333;
}

.page-blocker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  cursor: not-allowed;
}

img.visual-image-pageifo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  margin-top: 5rem;
}

h2.page-text-ifo {
  position: absolute;
  top: 16%;
  left: 5%;
  z-index: 1;
  margin: 0;
  padding: 0 5%;
  color: white;
  max-width: 60%;
  font-size: 2vw;
  line-height: 1.5;
}

.nav-item .btn-32 {
  padding: 10px 20px;
  border: 2px solid;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}

@media (max-width: 576px) {
  .cookie-logo {
    width: 280px;
    margin-bottom: 20px;
  }

  .clickable-img {
    cursor: pointer;
    width: 190px;
  }

  .nav-item button {
    font-size: 10px;
    padding: 8px 8px;
    border-radius: 3rem;
  }

  .nav-item .btn-32 {
    padding: 5px 5px;
    border: 2px solid;
  }
}

@media (max-width: 500px) {
  h2.page-text-ifo {
    font-size: 4.4vw;
  }

  .icon-enter-user {
    font-size: 1.2rem;
  }

  .intro-content {
    flex-direction: column;
    align-items: center;
  }

  .content-left {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .content-left h2 {
    font-size: 1.3rem;
    text-align: center;
  }

  .content-left p {
    font-size: 1rem;
    text-align: center;
  }

  .logo-image {
    width: 100%;
  }

  .page-blocker {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    cursor: not-allowed;
  }
}

.page-blocker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  pointer-events: all;
}
