.footer {
  background: linear-gradient(45deg, #000000, #012548, #000000);
  color: white;
  padding: 40px 0;
  position: relative;
}

.footer h6 {
  color: #ffffff;
  font-weight: bold;
}

.footer p {
  color: #ffffff;
  margin: 5px 0;
}

.social-icons a {
  font-size: 1.5rem;
  color: #bcefff;
  margin: 0 10px;
  transition: color 0.3s ease;
  
}

.social-icons a:hover {
  color: #7c4dff;
}

.footer hr {
  background-color: #7c4dff;
  height: 2px;
}

@media (max-width: 768px) {
  .footer .container {
    padding: 0 15px;
  }

  .footer h6 {
    font-size: 1.2rem;
  }

  .footer p {
    font-size: 0.9rem;
  }

  .social-icons a {
    font-size: 1.2rem;
  }
}

html,
body {
  height: 100%;
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}
