.learn-more-container {
  color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
}

/* Imagem Visual */
.visual-image {
  max-width: 100%;
  height: auto;
}

/* Títulos das Seções */
.section-title-dados {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 2rem;
}

/* Descrições */
.page-description {
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Cards de Funcionalidades */
.features-container {
  margin-top: 2rem;
}

.feature-card {
  background-color: #1e1e1e; /* Fundo dos cards */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.feature-icon {
  font-size: 3rem;
  color: #17a2b8; /* Cor dos ícones */
}

@media (max-width: 576px) {
  .section-title-dados {
    font-size: 1.5rem;
  }

  .page-description {
    font-size: 1rem;
    padding: 0 10px;
  }

  .feature-icon {
    font-size: 2.5rem;
  }
}
