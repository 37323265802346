/* src/components/UserProfile.css */

.avatar-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .avatar-item {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.3s ease;
  }
  
  .avatar-item.selected {
    border: 2px solid #17a2b8;
  }
  
  .avatar-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media (max-width: 576px) {
    .avatar-item {
      width: 80px;
      height: 80px;
    }
  }
  